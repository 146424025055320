import type { Dimensions } from './Dimensions'

export enum CableHoleType {
  tkm = 'step4.tkm',
  ukm = 'step4.seal',
  cutout = 'step4.cutout',
}

export class CableHole {
  constructor (
    public width: number,
    public height: number,
    public top: number,
    public left: number,
    public topPercentage: number,
    public leftPercentage: number,
    public label?: string,
    public price?: number,
    public type?: CableHoleType,
    public position?: CableHolePosition,
    public sealType?: SealHoleType,
  ) {}

  static fromJson (json: Record<string, any>): CableHole {
    return new CableHole(
      json.width,
      json.height,
      json.top,
      json.left,
      json.top_percentage,
      json.left_percentage,
      json.label,
      json.price,
      json.type === 1 ? CableHoleType.cutout : json.type === 2 ? CableHoleType.ukm : json.type === 3 ? CableHoleType.tkm : undefined,
      json.position,
      json.seal_type,
    )
  }

  public toJson (): Record<string, any> {
    return {
      width: this.width,
      height: this.height,
      top: this.top,
      left: this.left,
      top_percentage: this.topPercentage,
      left_percentage: this.leftPercentage,
      label: this.label,
      price: this.price,
      type: this.type === CableHoleType.cutout ? 1 : this.type === CableHoleType.ukm ? 2 : this.type === CableHoleType.tkm ? 3 : undefined,
      position: this.position,
      seal_type: this.sealType,
    }
  }
}

export enum CableHolePosition {
  top,
  topLeft,
  topRight,
  bottom,
  back,
}

export enum SealHoleType {
  circles,
  squares,
  tkm,
}

// UKM data
export const ukmWidth = 230
export const ukmHeight = 170
export const ukm30Price = 3500
export const ukm45Price = 3900
export const ukm90Price = 4500
export const tkm30Price = 400
export const tkm45Price = 800
export const tkm90Price = 1200

interface SealOption {
  title: string
  width: number
  height: number
  type: CableHoleType
  sealType: SealHoleType
}

export const ukmOptions: SealOption[] = [
  {
    title: 'UKM-1 (2ks 50mm + 16ks 22mm)',
    width: ukmWidth,
    height: ukmHeight,
    type: CableHoleType.ukm,
    sealType: SealHoleType.circles,
  },
  {
    title: 'UKM-2 (2ks 77x110mm)',
    width: ukmWidth,
    height: ukmHeight,
    type: CableHoleType.ukm,
    sealType: SealHoleType.squares,
  },
]

// TKM data
export const tkmOptions = (dimensions: Dimensions): SealOption[] => {
  const options: SealOption[] = [
    {
      title: 'TKM-1 150x80mm',
      width: 150,
      height: 80,
      type: CableHoleType.tkm,
      sealType: SealHoleType.tkm,
    },
    {
      title: 'TKM-2 250x80mm',
      width: 250,
      height: 80,
      type: CableHoleType.tkm,
      sealType: SealHoleType.tkm,
    },
  ]
  if (dimensions.d > 350) {
    const additionalOptions: SealOption[] = [{
      title: 'TKM-3 150x120mm',
      width: 150,
      height: 120,
      type: CableHoleType.tkm,
      sealType: SealHoleType.tkm,
    },
    {
      title: 'TKM-4 250x120mm',
      width: 250,
      height: 230,
      type: CableHoleType.tkm,
      sealType: SealHoleType.tkm,
    },
    ]
    if (dimensions.w > 600)
      additionalOptions.push({
        title: 'TKM-5 350x120mm',
        width: 350,
        height: 120,
        type: CableHoleType.tkm,
        sealType: SealHoleType.tkm,
      })
    return [...options, ...additionalOptions]
  }

  return options
}
